<template>
  <div v-if="data.articles.length" class="article-card">
    <div class="article-card__wrapper">
      <a
        :href="`${data.path}/${item.slug}`"
        :class="'article-card__link'"
        v-for="(item, index) in data.articles"
        :key="index"
      >
        <div
          :style="{ backgroundImage: 'url(' + item.media.cover[0].url + ')' }"
          class="article-card__image"
        ></div>
        <div v-html="getContent(item)" :class="`article-card__text `"></div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        articles: [],
        path: '',
      }),
    },
  },
  data() {
    return {}
  },

  methods: {
    getContent(item) {
      return `<h3>${item.title}</h3>${item.content}`
    },
  },
}
</script>

<style lang="scss">
.article-card {
  max-width: 1400px;

  margin: 0 auto;
  line-height: 1.2;

  padding: 10px 6.5%;

  @include respond-above('phone') {
    padding: 20px 10px;
  }
  @include respond-above('tablet') {
    padding: 30px 15px;
  }

  &__wrapper {
    display: flex;

    flex-direction: column;
    flex-wrap: wrap;
    --card-gap: 10px;
    gap: var(--card-gap);

    @include respond-above('phone') {
      flex-direction: row;
      --card-gap: 30px;
    }
  }

  &__link {
    @include respond-above('phone') {
      flex: 1 0 calc(50% - var(--card-gap));
    }

    @include respond-above('tablet') {
      flex: 1 0 calc(33% - var(--card-gap));
    }

    --min-height: 220px;

    display: flex;
    flex-direction: column;
    --card-spacing: 2rem;

    color: var(--primary-contrast);
    background-color: rgb(69, 83, 96);
    text-decoration: none;
    transition: transform 0.3s, box-shadow 0.3s ease;
    &:hover {
      text-decoration: none;
      transform: scale(1.04);
    }
  }

  &__text {
    max-width: 600px;
    word-break: break-word;
    padding: var(--card-spacing);

    h3,
    h2 {
      font-size: 1.25em;
      padding: 0;
      margin: 0 0 0.5em;
      font-weight: 400;

      @include respond-above('phone') {
      }
    }
    p {
      strong {
        font-weight: 400;
      }

      @include respond-below('phone') {
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  &__image {
    // for animation
    opacity: 0;

    min-height: var(--min-height);

    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
  }
}
</style>
